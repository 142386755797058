import React from 'react'
import Footer from '../FOOTER/Footer'

const Softeware = () => {
  return (<>
    <div className='about-text'>
      <div className='container p-2'>
        <h2>Softwares We<span id="us"> Deal With </span></h2>
        <p>With the advent of globalization a decade ago and an  open door policy being adopted by India, the world's outlook towards India has undergone a massive change. India has played a major contribution and crucial role in becoming one of the key leaders in the field of Information Technology development and ITES HUB. As a result, a huge market demand for IT infrastructure and solutions is being observed in the recent past. GenXcel Info Solutions Pvt. Ltd. is your most trusted partner for success, providing high tech, low cost and sustainable IT solutions that will enhance your efficiency, productivity, quality, data safety of resources to place your organization in global standard.</p>
        <p>GenXcel Info Solutions has wide range software solution from India and overseas. We have associations with many renowned companies, software solution providers and successfully installed many licenses at many client place. GenXcel Info Solutions also provide Annual Maintance services for the software and licenses.</p>
      </div>
    </div>

    <div>
      <div className='container fs-5 mt-4 shadow-lg p-4 mb-5' >
        <h3>GenXcel Info Solutions offer's below Software Solutions :</h3>
        <div className='row'>
          <div className='col-lg-6 col-md-6'>
            <ul>
              <li>Lync.</li>
              <li>Adobe.</li>
              <li>VMware.</li>
              <li>Citrix.</li>
              <li>AutoCAD.</li>
              <li>AutoDesk.</li>
              <li>Microsoft Office 365.</li>
              <li>Network management tools.</li>
              <li>Microsoft Operating Software.</li>
              <li>Server & Storage management tools.</li>
            </ul>
          </div>
          <div className='col-lg-6 col-md-6'>
            <ul>
              <li>Cisco.</li>
              <li>Ansys.</li>
              <li>Commvault.</li>
              <li>Talley ERP.</li>
              <li>File Maker Pro.</li>
              <li>InfoWork Software.</li>
              <li>Geo Fluid Software's.</li>
              <li>Virtual Load Balancer (VLM)</li>       
              <li>Any other indigenous & imported software solutions.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer/>

  </>)
}

export default Softeware