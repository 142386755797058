import React from "react";
// import "./footer.css";
import { NavLink } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";
let Footer = () => {
    return (<>
        <div className="container-fluid  p-2  text-light bg-dark">
            <div className="container mt-5-">
                <div className="row d-flex justify-content-center mt-4">
                    <div className="col-lg-2 col-md-2 col-sm-6 col-12">
                        <h4>MENUE</h4>
                        <ul className=" ">
                            <li><NavLink to="/home" className="nav-link"><a>Home</a></NavLink></li>
                            <li><NavLink to="/about" className="nav-link"><a>About</a></NavLink></li>
                            <li><NavLink to="/products" className="nav-link"><a>Products</a></NavLink></li>
                            <li><NavLink to="/services" className="nav-link"><a>Services</a></NavLink></li>
                            <li><NavLink to="/Contactus" className="nav-link"><a>Contact</a></NavLink></li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <h4>CONTACT</h4>
                        <div className="row">
                            <div className="col-sm-1">
                                <FaLocationDot />
                            </div>
                            <div className="col-sm-10">
                                <p>GenXcel Info Solutions Private Limited Office No G-6,
                                    Ashoka Plaza, Ground Floor, Viman Nagar,
                                    Nagar Road, Dominos Pizza,</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-1">
                                <FaPhoneAlt/>
                            </div>
                            <div className="col-sm-10">
                                <p> <a class="text nav-link" href="tel:986-009-9400">+91-9860099400</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                        <h4>QUICK LINKS</h4>
                        <ul>
                            <li> <NavLink to="/Link1" className="nav-link"><a >IT SERVICES & CONSULTING</a></NavLink></li>
                            <li><NavLink to="/Link2" className="nav-link"><a>IT SOFTWARE & SERVICES</a></NavLink></li>
                            <li><NavLink o="/Link3" className="nav-link"><a>IT STAFFING & RECRUITING</a></NavLink></li>
                            <li><NavLink to="/Link4" className="nav-link"><a>IT ENABLED SERVICES</a></NavLink></li>
                            <li><NavLink to="/PLink1" className="nav-link"><a>IT SOFTWARES & CONSULTING</a></NavLink></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                        <h4>SOCIAL MEDIA LINKS</h4>
                        <div className=" row mt-4 fs-4 p-3 s-logo ">
                            <div className="col"> <a href="https://www.facebook.com/GENXCEL" class="bi bi-facebook text-light"></a></div>
                            <div className="col">  <a class="bi bi-twitter-x text-light"></a></div>
                            <div className="col"> <a href="https://www.linkedin.com/company/genxcel-info-solutions" class="bi bi-linkedin text-light"></a></div>
                            <div className="col"><a class="bi bi-instagram text-light"></a></div>
                            <div className="col"><a href="https://wa.me/+919860099400" class="bi bi-whatsapp text-light"></a></div>
                        </div>
                    </div>
                </div>
                <div>
                    <p  className="text-center mt-5"><FaRegCopyright/> 2024 Genxcel Info Solutions Privated Limited</p>
                </div>
            </div>

        </div>
    </>);
}
export default Footer;