import React from "react";
import Footer from "../FOOTER/Footer";
import "./about.css";

let About = () => {
    return (<>
        <div className="p-1" style={{ backgroundColor: " #ebf4f5" }}>
            <div className="container mt-5" >
                <h4 className="text-center mb-4">GenXcel: Your Trusted Partner for Tailored IT Solutions</h4>
                <p>At <strong>GenXcel</strong>, we craft bespoke solutions that empower your business to thrive. Our passion lies in understanding your unique challenges and delivering world-class solutions from leading brands like HP, Lenovo, Microsoft and more. </p>
                <p>The leadership team consists of seasoned IT veterans knows the landscape inside-out, ensuring you get the best advice and service. We put your needs at the heart of everything we do, exceeding expectations and exceeding satisfaction. Genxcel Info Solutions offer premium solutions at value-driven prices, optimizing your IT budget without compromising quality. From hardware and software to network security and data management to outsourcing services, we tackle your most critical needs with reliable efficiency. We're a nationwide IT partner, serving businesses across India with dedicated support and local expertise.</p>
                <p>In the digital jungle, where technology roars and algorithms hum, navigating the path to success can feel like deciphering an ancient code. At GenXcel Info Solutions Private Limited, we're not just IT experts, we're digital anthropologists, decoding the language of your business and translating it into solutions that elevate your journey.</p>
                <p>We recognize that technology is not an isolated entity, but a vibrant ecosystem woven into the fabric of your organization. We delve deeper than servers and software, understanding your culture, your people and your aspirations. We see the human stories behind the data the frustrations buried beneath the technical jargon, and the triumphs waiting to be unlocked</p>
            </div>
        </div>
        <div className="container p-2 mb-5">
            <div className="row text-center gap-3 mt-5">
                <div className="col-sm">
                    <div className="masthead img-3">
                        <div className="color-overly">
                            <div className="container master">
                                <h3>OUR VALUE</h3>
                                <p>
                                    Highly Professional Work Culture with Open door policy.
                                    Honesty, Integrity and Ethical Business Practices
                                    Team Work, Continues Learning of the innovation of technologies.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="masthead img-4">
                        <div className="color-overly">
                            <div className="container master">
                                <h3>OUR QUALITY POLICY</h3>
                                <p>
                                    GenXcel Info Solutions Pvt. Ltd. is dedicated to improve customer
                                    satisfaction by fulfilling customer requirements and expectations regarding
                                    product delivery, services, statutory and other regulatory compliances.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div className="container p-2">
            <h4 className="text-center mt-5">Our Digital Decoder Ring:</h4>
            <div className="row gy-3 mt-3 ">
                <div className="col-lg-4">
                    <div class="card p-3 pb-1 about-cards ">
                        <div class="card-body">
                            <h5 class="card-title">Cyber Sherlocks:</h5>
                            <p>•  We build impenetrable fortresses with cutting-edge cyber security solutions, keeping your data safe from prowling threats and prying eyes.</p>
                        </div>
                    </div>
                </div>


                <div className="col-lg-4">
                    <div class="card p-2 about-cards">
                        <div class="card-body">
                            <h5 class="card-title">Cloud Architects:</h5>
                            <p>•  We map the ever-shifting cloud landscape, crafting bespoke cloud strategies that unlock its boundless potential for scalability, agility, and cost-efficiency.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div class="card p-2 about-cards">
                        <div class="card-body">
                            <h5 class="card-title"> Network Weavers:</h5>
                            <p>• From fiber-optic arteries to wireless whispers, we weave a robust network tapestry that transmits your information with lightning speed and unwavering reliability.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row gy-3 mt-3">
                <div className="col-lg-4">
                    <div class="card p-2 about-cards">
                        <div class="card-body">
                            <h5 class="card-title">Software Alchemists:</h5>
                            <p>•  We transform your workflow into a seamless symphony, crafting custom software solutions that automate tasks, eliminate friction, and empower your team.</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div class="card p-2 about-cards">
                        <div class="card-body">
                            <h5 class="card-title"> Data Sorcerers:</h5>
                            <p> • We unlock the hidden magic within your data, transforming it into actionable insights that fuel informed decisions, optimize operations, and propel growth.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div class="card p-2 about-cards">
                        <div class="card-body">
                            <h5 class="card-title">  24/7 Tech Whisperers:</h5>
                            <p>• We're your ever-present companions, troubleshooting glitches, resolving queries, and ensuring your digital journey remains smooth and uninterrupted.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container p-2 mb-5">
            <div className="row text-center gap-3 mt-5">
                <div className="col-sm">
                    <div className="masthead">
                        <div className="color-overly">
                            <div className="container master">
                                <h3>VISION:</h3>
                                <p>To be the most trusted & preferred customer centric partner in IT,
                                    Electronic and Electrical products and earn undisputable reputation for
                                    after-sales services.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="masthead img-2">
                        <div className="color-overly">
                            <div className="container master">
                                <h3 >MISSION:</h3>
                                <p>
                                    To build strong alliances to encouragement long-term association with
                                    customer as well as technology partners to Provide best-in-class products
                                    and solutions with great service quality to customers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}

            <div className="col-sm mt-5 text-center">
                <div className="masthead img-5">
                    <div className="color-overly">
                        <div className="container master">
                            <h3>OUR STRENGTH:</h3>
                            <p>
                                GenXcel Info Solutions has a very unique approach with honest effort to understand the customers' needs and expectations and make the best effort to fulfill
                                with the best utilization of resources. We value association with all our patrons and work with them as their trusted partners. These solutions that we design
                                considering a long-term perspective with scalability are backed up by our committed technical support team.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="mt-5 mb-5 container">
            <p>We believe in a dance, not a dictatorship. We collaborate, listen, and adapt, tailoring our solutions to your unique rhythm. We empower your team, equipping them with the knowledge and tools to be confident co-pilots on the digital journey. We understand that technology is a tool, not a tyrant, and guide you in wielding it to its fullest potential.</p>
            <p>Whether you're a fledgling startup taking your first digital steps, a mid-sized company navigating growing pains or a seasoned enterprise veteran on the cusp of transformation, GenXcel Info Solutions welcomes you to the tribe. We offer not cookie-cutter solutions, but bespoke tools and expertise forged in the fires of collaboration. We don't just bridge the digital divide; we build bridges together, brick by innovative brick, fueled by a shared vision of your success.</p>

            <h5>Here's a glimpse into our digital world:</h5>
            <div className="ms-3">
                <p>•<b>Innovation Labs:</b> A playground for creativity, where we experiment with cutting-edge technologies and develop solutions that push the boundaries of the possible.</p>
                <p>•<b>Client Success Stories:</b> Real-world examples of how we've partnered with businesses to unlock their digital potential, empowering them to achieve remarkable results.</p>
                <p>•<b>Community Engagements:</b> We don't just talk the talk, we walk the walk. We actively participate in industry events, share knowledge, and foster a thriving community of tech enthusiasts.</p>

            </div>
            <p>We'll be your Partner, your allies and your unwavering champions as you navigate the ever-evolving digital landscape. Together, we'll weave a story of success, written in lines of code and fueled by the boundless potential of your vision.</p>
            <strong>Contact us today and let's decipher the unique code of your business together</strong>
        </div>
        <Footer />
    </>);
}
export default About;