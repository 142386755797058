import React from 'react'
import MainIng from "../Used Images/SERVICES/Link3/MainIng.jpg"
import Footer from '../FOOTER/Footer';

const Link3 = () => {
  return (<>

    <div className='link-1 position-relative'>
      <div class="card1">
        <img src={MainIng} loading="lazy" alt="Card 1 Image" />
        <div class="overlayy">
          <h2>IT Staffing & Recruiting</h2>
        </div>
      </div>
    </div >

    <div className='Link-3'>
      <div className='p-1 '>
        <div className='container my-4'>
          <p>Welcome to<strong> Genxcel Info Solutions,</strong> The first step to unmatched staffing and recruiting solutions in the technology world. As a premier IT consulting and staffing services provider, we specialize in delivering top-quality technical and software professionals to market leaders worldwide on a contract to hire, Manpower on the Go and permanent basis. Collaboration with Genxcel to meet your hiring needs and achieve long-term business goals with utmost precision.</p>
        </div>
      </div>

      <div className='container'>
        <div className='row shadow-lg p-2 bg-secondary text-white m-2'>
          <div className='col-lg-4 col-md-12 col-sm-12 ' >
            <h3>Contract-to-Hire</h3>
            <p>Would you have short term project to cater without putting much pressure on the human resource budget. We manage and hire your need on our payroll to optimize resource allocation with our flexible contract-to-hire roles. Evaluate an employee’s performance before offering permanent employment or stay on our payroll. </p>
          </div>
          <div className='col-lg-4 col-md-12 col-sm-12'>
            <h3>Manpower on the Go</h3>
            <p>Hire on the go enables you to conclude the most urgent needs of resource to fulfill the immediate needs. This shall gain confidence in your hiring decisions through the collaboration of our “Manpower on the Go”. We ensure the perfect fit for your organizational goal. </p>
          </div>
          <div className='col-lg-4 col-md-12 col-sm-12'>
            <h3>Permanent Hiring</h3>
            <p>Proficient hiring team discovers the best talent for your full-time positions without impacting the financial health of your company to achieve organizational goal. The bare minimum professional fees in lieu of supply of talent ensure you have access to exceptional resources.</p>
          </div>
        </div>
        <div className='p-1'>
          <div className='row mt-5'>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <h4><strong>The Technology we hire for</strong> –</h4>
              <p>including but not limited to </p>
              <ol>
                <li>Microsoft Technologies, Infra Management Services.</li>
                <li>Microsoft Dynamics CRM, Azure / IBM BPM</li>
                <li>	Strong ERP proficiency in SAP All modules.</li>
                <li>Certified resources: Project Managers PMP, ITIL, and Agile Scrum Master</li>
                <li>Web Designing & Development & App Development, QA & Testing</li>
                <li>Cyber Security Oracle PL/ SQL, Oracle DBA, Siebel, PeopleSoft</li>
              </ol>

            </div>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='row'>
                <div className='col-lg-7 col-md-12 col-sm-7'>
                  <h4>Our Process</h4>
                  <ol>
                    <li>Job Analysis and Planning</li>
                    <li>Aspirant Sourcing</li>
                    <li>Aspirant Evaluation</li>
                    <li>Interviews Schedule & Coordination</li>
                    <li>Perform Background Verification</li>
                    <li>Rolling Offer and Joining</li>
                    <li>Seamless Joining & On boarding</li>
                  </ol>
                </div>
                <div className='col-lg-5 col-md-12 col-sm-5'>
                  <h4>Our Edge.</h4>
                  <ol>
                    <li>Databank of Qualified Candidates</li>
                    <li>	Seamless Aspirant Screening</li>
                    <li>	Seamless On boarding</li>
                    <li>	Swift Replacements</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='container p-1'>
          <h4 className='mt-3'>Our Guarantee – We Stand Behind Our Services</h4>
          <p>Genxcel stand by alongside with you to safeguard your smooth operation by monitoring closely on the qualified candidates we hire. As per industry standard, We shall replace non performer within 90 days, provided all contractual obligations have been met.</p>
          <p>Our leadership team has begun their career as a human resource consultant nearly two decade ago. We as Genxcel established itself as a desirable organization that elevates your staff’s productivity and talent pool. </p>
        </div>
      </div>
    </div>
    <Footer />
  </>)
};

export default Link3