import React from 'react'
import mainimg from "../Used Images/SERVICES/Link2/mainimg.jpg"
import img1 from "../Used Images/SERVICES/Link2/img1.jpg"
import img2 from "../Used Images/SERVICES/Link2/img2.jpg"
import img3 from "../Used Images/SERVICES/Link2/img3.png"
import img4 from "../Used Images/SERVICES/Link2/img4.jpg"
import img5 from "../Used Images/SERVICES/Link2/img5.png"
import { FaWhatsapp } from "react-icons/fa";
import Footer from '../FOOTER/Footer'

const SoftwareAndServices = () => {
  return (<>

    <div className=" position-relative link-2 bg-b" >
      <div className="position-relative w-100 h-100" >
        <img src={mainimg} loading="lazy" className="img-fluid d-block w-100 h-100 " />
        <div className="position-absolute w-100 h-100 top-0 left-0 text-center d-flex justify-content-center align-items-center text-white">
          <h2>Website & Application <br />Development Services</h2>
        </div>
      </div>
    </div>

    <div className='about-text mt-4'>
      <div className='container p-1 '>
        <p className='mt-4'>At Genxcel Info Solutions, we offer Website and other application development & Maintenance services to uplift your digital presence globally. A team of proficient developers engaged round the clock to cater your needs in a firefighting manner. Digital presence is the key for the success of today’s world. We at Genxcel Info Solutions offer a one stop solution to each and every business owner to focus on your core competencies and leave aside the worry of your digital presence and infrastructure worries to us.</p>
      </div>
    </div>

    <div className='container p-1 '>
      <div className='row reverse my-5 d-flex align-items-center '>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <h2> Website Development Service</h2>
          <p>
            Craft Your Online Oasis with the powder of the approachable Website Development Services with GenXcel Info Solutions. Your website is more than just an address, it's your virtual home, your brand's billboard and your gateway to success. But building a website that captivates converts and stands out needs the expertise of a skilled architect. We at GenXcel Info Solutions stand for you to craft your online Oasis.

            Our website development services are meticulously crafted to cater to your unique needs. Whether you're a startup or a seasoned enterprise, we collaborate to understand your vision, target audience and desired functionality.  We optimize your website for SEO & Built in secure plate form to safeguard your data and visitor trust.

            We stay abreast of the latest trends and technologies, continuously optimizing your online presence for maximum impact. In the ever-evolving digital landscape, your website is your most valuable asset.

            Invest in its creation with the power of website development services. Let us build you a digital oasis that attracts, engages, and converts, propelling your brand to new heights.
          </p>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>

          <img src={img1} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
      </div>

      <div className='row my-3 d-flex align-items-center '>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>

          <img src={img2} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 '>
          <h2> SEO & Digital Marketing:</h2>
          <p>
            Unmasking the Mystery of Growth with SEO & Digital Marketing. SEO and Digital marketing help all those business losing the ground in the vast digital jungle.

            Climb the in the Search Engine with a sturdy ladder to the top of Google's search results. We optimize your website and content with relevant keywords, attracting the right eyes to your virtual storefront.

            SEO and digital marketing like two instruments in a harmonious orchestra. One guides the way; the other fills the air with captivating melodies. Working together, they create an irresistible performance that attracts, engages, and converts.
            Embrace the power of SEO and digital marketing with the help of Genxcel Info Solutions. Let us be your digital conductor to craft a symphony that resonates with your audience and drives your business to success.
          </p>
        </div>
      </div>

      <div className='row reverse my-3 d-flex align-items-center '>

        <div className='col-lg-6 col-md-6 col-sm-12'>
          <h2> Mobile App Development</h2>
          <p>
            In GenXcel Info Solutions, we develop mobile app isn't just a trend, it's a strategic move. The mobile App is always Available & engaging your customers as your brand's ambassador. Consistent companion, accessible anytime, anywhere and engage your audience on the go, build loyalty through instant interactions and turn fleeting moments into loyal customers.

            Genxcel Info Solutions design apps that are intuitive, user-friendly and optimized for the mobile experience. From sleek interfaces to smooth navigation, your app becomes a joy to use, not a frustrating chore. Mobile apps aren't just static pages, they're dynamic portals. We integrate cutting-edge features like push notifications, location-based services and augmented reality, making your app an interactive playground for your audience. App stores offer a global stage for your brand.

            In the world of mobile. Don't get left behind. Embrace the power of mobile app developed for your business and let us craft a pocket-sized powerhouse that puts your brand in the palm of your customers' hands.
          </p>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>

          <img src={img3} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
      </div>

      <div className='row my-3 d-flex align-items-center '>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>

          <img src={img4} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <h2> ERP | CRM Development</h2>
          <p>
            Unleash your business Powered by a customized ERP & CRM specially built for your business. In today's business jungle, where information reigns supreme power. Efficient utilization of data is the key to survival.  Adapting the customized ERP and CRM bring order and agility to your operations. ERP systems break down data silos, offering real-time insights, streamlined workflows and improved decision-making. No more chasing spreadsheets.

            GenXcel Info Solutions just don’t develop and implement ERP or CRM software; we customize it to your unique needs. We integrate your existing systems, tailor dashboards for actionable insights, and configure workflows that empower your team. We believe technology should serve your business, not the other way around.

            Embrace the power of ERP and CRM & Let GenXcel Info Solutions be your conductor to navigate the complex landscape and crafting customized solutions that unlock your business potential. Take control, optimize your operations and watch your company flourish in the digital wilderness.
          </p>
        </div>
      </div>

      <div className='row reverse my-3 d-flex align-items-center '>

        <div className='col-lg-6 col-md-6 col-sm-12'>
          <h2> Web Base Application</h2>
          <p>
            Web-based applications isn't just a nice-to-have, it's a must-have for today business. The era of the clunky software, endless updates and device limitations is an old story. The Web-based applications have swept in & usher in a new era of accessibility, flexibility and collaboration.

            We design and offer you web-based applications as per your business need, which run seamlessly on any device with a browser. Work from anywhere, access your data anytime and say goodbye to location-based limitations access from anywhere and anytime. Web-based applications are powerhouses to work on projects together, access, share dashboards and stay in sync like never before and provide robust security infrastructure giving you peace of mind and protecting your valuable information.

            Our Web-based applications are often subscription-based, making them budget-friendly and adaptable to your growing needs with flexibility and accessibility, revolutionizing the way we work, collaborates and access information.

            Make the switch and embrace the future of applications the future is online.
            Contact us for more assistance and inputs!
          </p>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>

          <img src={img5} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
      </div>
    </div>

    {/* Website Planes */}
    <div className="container-fluid p-1  about-text py-5">
      <div className='container mt-3'>
        <h2 className='text-center mb-5'>Website Development Plans</h2>
        <div class="row gy-3">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="card about-cards">
              <div class="card-body">
                <h4 class="card-title">Basic Plan -Static Website</h4>
                <h5>Price RS. 15,000/-</h5>
                <ul class="card-text mt-4">
                  <li>Upto 5 Pages</li>
                  <li>Quick Inquiry Form</li>
                  <li>Google Map Integration</li>
                  <li>Social Media Integration</li>
                  <li>Responsive In All Devices</li>
                  <li>SEO </li>
                  <li>Intractive Design</li>
                  <li>Website Hosting  - 1 Year</li>

                  <li>Domain - 1 Year</li>
                </ul>
                <div className='text-center mt-4'>
                  <a class="btn btn-primary "><p> <a class="text nav-link mt-3" href="tel:986-009-9400"> <FaWhatsapp  className='text-info fs-5 '/> Contact Us</a></p></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="card about-cards">
              <div class="card-body">
                <h4 class="card-title">Ecommerce Website Plan</h4>
                <h5>Price RS. 50,000/-</h5>
                <ul class="card-text mt-4">
                  <li>Multi-Page Design</li>
                  <li>Upto 20 Products</li>
                  <li>Social Media Integration</li>
                  <li>Responsive In All Devices</li>
                  <li> SEO</li>
                  <li>Admin Panel</li>
                  <li>Payment gateway Integration</li>
                  <li>Intractive Design</li>
                  <li>Website Hosting  - 1 Year</li>
                  <li>Domain - 1 Year</li>
                  <li>Yearly Renewal</li>
                  <li>SSL Certificate</li>
                  <li>Quick Inquiry Form</li>
                </ul>
                <div className='text-center mt-4'>
                  <a class="btn btn-primary "><p> <a class="text nav-link mt-3" href="tel:986-009-9400"> <FaWhatsapp  className='text-info fs-5'/> Contact Us</a></p></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 ">
            <div class="card about-cards">
              <div class="card-body">
                <h4 class="card-title">Standard Plan Dynamic Website</h4>
                <h5>Price RS. 30,000/-</h5>
                <ul class="card-text mt-4">
                  <li>Multi-Pages</li>
                  <li>Google Map Integration</li>
                  <li> Quick Inquiry Form</li>
                  <li>Intractive Design</li>
                  <li>Social Media Integration</li>
                  <li>Responsive In All Devices</li>
                  <li> SEO</li>
                  <li> 2 Business Email Id</li>
                  <li>Website Hosting  - 1 Year</li>
                  <li>Domain - 1 Year</li>
                  <li>Lifetime Validity</li>
                  <li>Yearly Renewal</li>
                  <li>SSL  Certificate</li>
                </ul>
                <div className='text-center mt-4'>
                  <a class="btn btn-primary "><p> <a class="text nav-link mt-3" href="tel:986-009-9400"> <FaWhatsapp className='text-info fs-5' /> Contact Us</a></p></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Footer />
  </>)
}

export default SoftwareAndServices;