import React from "react";
import "./Products.css"
import Footer from "../FOOTER/Footer";
import f1 from "../Used Images/Products/img1.jpg"
import f2 from "../Used Images/Products/img2.jpg"
import f3 from "../Used Images/Products/img3.jpg"
import f4 from "../Used Images/Products/img4.jpg"
import f5 from "../Used Images/Products/img5.jpg"
import f6 from "../Used Images/Products/img6.jpg"
import f7 from "../Used Images/Products/img7.png"
import f8 from "../Used Images/Products/img8.jpg"



let Products = () => {
    return (<>
        <div className="container-fluid product-pragraph" style={{ textAlign: "justify" }}>
            <div className="container p-3">
                <h1>IT <span id="us">Product</span></h1>
                <p className="mt-3">
                    With the advent of globalization a decade ago and an open door policy being adopted by India, the world's outlook towards India has undergone a massive change. India has
                    played a major contribution and crucial role in becoming one of the key leaders in the field of Information Technology development and ITES HUB. As a result, a huge market
                    demand for IT infrastructure and solutions is being observed in the recent past. GenXcel Info Solutions Pvt. Ltd. is your most trusted partner for success, providing high tech,
                    low cost and sustainable IT solutions that will enhance your efficiency, productivity, quality, data safety of resources to place your organization in global standard.
                </p>
            </div>
        </div>


        <div className="container product-img text-center">
            <div className="row text-center my-5  align-items-center" id="a1">
                <div className="col-md-6">
                
                    <img src={f1} loading="lazy" alt="" className="h-100 border border-2 border-dark p-2" />
                </div>
                <div className="col-md-6 ">
                    <h2>Computing Solutions</h2>
                    <p>It’s time to embrace individuality with Desktop, Workstation, and Laptop solutions that fit your unique needs like a glove.</p>
                    <p>Whether you're a creative powerhouse or a data crunching master, powerful desktops deliver raw processing muscle. Imagine editing 4K videos with zero lag, running complex simulations at warp speed or tackling demanding workloads with unwavering power.</p>
                    <p>For those who push the boundaries of design, engineering, and scientific computing, workstations are your digital battle tanks. Experience next-level performance with specialized graphics cards, high-performance processors, and robust cooling systems. Render photorealistic models in a blink, crunch massive datasets effortlessly, and conquer any creative challenge that comes your way.</p>
               </div>
            </div>

            <div className="row row-2  reverse my-5  align-items-center" id="a2">
                <div className="col-md-6 ">
                <h2> Server & Storage</h2>
                    <p>The Power of Server & Storage is the lifeblood of your business – safe, secure, and instantly accessible. </p>
                    <p>We craft bespoke server & storage solutions that rocket your data to new speeds. We build impregnable digital fortresses with layered security, advanced threat detection, and disaster recovery plans. Sleep soundly knowing your information is safeguarded at every turn.</p>
                    <p><strong>Genxcel Info Solutions</strong> is not just hardware vendors, we're your trusted data strategists. We analyze, consult, and craft tailor-made solutions that align with your specific goals and budget. Think of us as your digital architects, building a resilient data foundation for your future success. Your data holds untapped potential waiting to be harnessed. We help you extract insights, fuel innovation and drive informed decision-making. Transform your data from a passive asset to a proactive catalyst for growth.</p>
                    <strong>Contact us today and unlock the limitless potential of your information.</strong>
                    <hr></hr>
                </div>
                <div className="col-md-6">
                    <img src={f2} loading="lazy" alt="" className="h-100 border border-2 border-dark p-2" />
                </div>
            </div>

            <div className="row   my-5  align-items-center" id="a3">
                <div className="col-md-6">
                   
                    <img src={f3} loading="lazy" alt="" className="h-100 border border-2 border-dark p-2" />
                </div>
                <div className="col-md-6 ">
                <h2>  Networking Solution</h2>
                    <p>IT Networking Solutions are the maestro, conducting the digital symphony that drives your success. Break down isolated systems and connect every corner of your business. Secure, high-speed networks powered by fiber-optic lightning and intelligent routing ensure instant data flow, real-time collaboration and effortless communication across teams and locations.</p>
                    <p>We prioritize robust security with advanced firewalls, data encryption and proactive threat detection, safeguarding your network and keeping your data safe, even in the most virtuosic cyber attacks. We analyze your unique needs, craft tailor-made solutions and manage every aspect of your network, from installation to ongoing optimization. Think of us as your IT conductors, ensuring every note in your digital symphony rings true.</p>
                    <strong>Contact us today and hit the high notes of success!</strong>
                    <hr />
                </div>
            </div>

            <div className="row  reverse my-5  align-items-center" id="a4">
                <div className="col-md-6 ">
                <h2> CCTV Security & Surveillance</h2>
                    <p>A business is a vibrant hive of activity, but also a potential target for theft, vandalism or worse. </p>
                    <p>CCTV Security & Surveillance rise as the watchful gaze, safeguarding your assets and empowering you to see the full picture, day or night. Today's CCTV solutions are intelligent guardians, offering high-resolution video, intelligent analytics and real-time alerts. Deter crime before it happens, identify potential threats proactively and respond instantly to any incident.</p>
                    <p>Advanced video analytics systems process footage intelligently, recognizing faces, detecting anomalies and highlighting suspicious behavior. Imagine automatic alerts for unauthorized access, object removal or even crowd detection, allowing you to react effectively and efficiently.</p>
                    <p><strong>Genxcel Info Solutions</strong> is not just camera installers, we're your security partners. We assess your needs, design customized systems and ensure seamless integration with your existing infrastructure. Think of us as your vision architects, crafting a vigilant CCTV ecosystem that empowers you to see, understand, and protect your business like never before.</p>
                    <strong>Contact us today and see the difference a vigilant digital guardian can make!</strong>
                    <hr />
                </div>
                <div className="col-md-6">
                    
                    <img src={f4} loading="lazy" alt="" className="h-100 border border-2 border-dark p-2" />
                </div>
            </div>

            <div className="text-center align-items-center">
                <p class="d-inline-flex gap-1">
                    <a class="btn btn-info" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        Show More
                    </a>
                    <a class="btn btn-info" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="">
                        Show Less
                    </a>
                </p>
            </div>
            <div class="collapse" id="collapseExample">
                <div className="row align-items-center  my-5" id="a5">
                    <div className="col-md-6">
                       
                        <img src={f5} loading="lazy" alt="" className="h-100 border border-2 border-dark p-2" />
                    </div>
                    <div className="col-md-6 ">
                    <h2> Signage & Interactive Media</h2>
                        <p>Imagine the office walls that are dynamic canvases captivating audiences and amplifying your message. Digital Signage & Interactive Solutions rise as the artistic architects, transforming your spaces into vibrant hubs of engagement and information.</p>
                        <p>We craft breathtaking digital experiences with stunning high-resolution displays, immersive touch screens and mesmerizing video walls. Engage customers, inform employees and elevate your brand like never before. GenXcel help you craft compelling visuals and interactive experiences that resonate with your audience. Think real-time data feeds, personalized greetings and interactive campaigns that draw viewers in and leave a lasting impression.</p>
                        <p><strong>Genxcel Info Solutions</strong> is not just a screen vendor, we're your digital strategists. We analyze your space, tailor content, and provide ongoing support, ensuring your signage and interactive media is always fresh, impactful, and working for you. </p>
                        <strong>Contact us today and get ready to paint your message on the digital canvas!</strong>
                        <hr />
                    </div>
                </div>

                <div className="row  reverse align-items-center my-5 " id="a6">
                    <div className="col-md-6 ">
                    <h2>  Access Control Systems</h2>
                        <p>An Access Control Systems is an intelligent guardian, granting access to the trusted and securing your space with unwavering vigilance. </p>
                        <p>The cutting-edge access control solutions with biometric scans, smart phone credentials and advanced access rules. Imagine effortless entry for authorized personnel, while deterring intruders with multi-factor authentication and real-time monitoring.</p>
                        <p>Our systems offer granular control, restricting access to specific areas, granting temporary permissions, and even tracking movements within your facility. Ensure the right people have access to the right places, at the right times.</p>
                        <p>We integrate access control with video surveillance, alarm systems and even building automation, creating a holistic security ecosystem. Imagine doors unlocking automatically for authorized personnel, lights adjusting based on occupancy and immediate alerts in case of unauthorized access or security breaches.</p>
                        <strong>Contact us today and unlock the future of access control!</strong>
                        <hr />
                    </div>
                    <div className="col-md-6  ">
                       
                        <img src={f6} loading="lazy" loading="lazy" loading="lazy" loading="lazy" loading="lazy" alt="" className="img-fluid d-block h-100 border border-2 border-dark p-2" />
                    </div>
                </div>

                <div className="row align-items-center my-5 " id="a7">
                    <div className="col-md-6">
                       
                        <img src={f7} loading="lazy" alt="" className="h-100 border border-2 border-dark p-2" />
                    </div>
                    <div className="col-md-6 ">
                    <h2>Audio Video Solutions</h2>
                        <p>An Audio Video Solutions provides seamless presentations that land with impact and collaboration that transcends distance and communicate to impact.</p>
                        <p>We craft bespoke solutions with smart microphones, noise-canceling technology and high-fidelity speakers that ensure every word rings true, every nuance is heard. From boardrooms to conference halls, experience flawless audio that elevates every interaction. We bring your content to life with stunning displays, seamless video conferencing and immersive digital signage. Engage audiences, inspire teams and tell your story with visuals that captivate and connect.</p>
                        <p><strong>Genxcel Info Solutions</strong> analyze your needs, craft a tailor-made solution compiling various technologies and provide ongoing support and expertise. Don't let communication barriers hold you back. Adapt the power of Audio Video Solutions and elevate every interaction. </p>
                        <strong>Contact us today and turn up the volume on success!</strong>
                        <hr />
                    </div>
                </div>


                <div className="row align-items-center reverse my-5" id="a8">
                    <div className="col-md-6 ">
                    <h2> Printer & Office Solutions</h2>
                        <p>Forget cartridges gone dry and paper jams galore, Scanner malfunction.  Printer & Office Solutions rise as the workflow whisperer, transforming your document management from drudgery to a seamless symphony of efficiency. We offer multi-functional marvels that print, scan, copy and fax, streamlining tasks and minimizing clutter. Imagine documents digitized on the fly, shared wirelessly with ease and archived securely in the cloud.</p>
                        <p>Don’t Let the printing solution slow you down. We integrate printing seamlessly into your existing systems from automated invoicing to real-time document routing. Imagine documents automatically printing when needed, sent directly to the right team member and tracked for cost and efficiency.</p>
                        <p>Your office needs extend beyond printing. We offer a wealth of solutions from secure shredders and mailroom automation to ergonomically designed furniture and eco-friendly cleaning supplies. Don't let paper pile-ups and printing woes hold you back. Adapt the power of Printer & Office Solutions and watch your workflow flow. </p>
                        <strong>Contact us today and let's unlock the next level of office efficiency together!</strong>
                        <hr />
                    </div>
                    <div className="col-md-6 ">
                        
                        <img src={f8} loading="lazy" alt="" className="h-100 border border-2 border-dark p-2" />
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>);
}
export default Products;