import React from 'react'
import MainIng from "../Used Images/SERVICES/standard-quality-control-concept-m (2).jpg"
import img4 from "../Used Images/SERVICES/Link1/img4.jpg"
import img5 from "../Used Images/SERVICES/Link1/img5.jpg"
import img6 from "../Used Images/SERVICES/Link1/img6.jpg"
import img7 from "../Used Images/SERVICES/Link1/img7.jpg"
import img8 from "../Used Images/SERVICES/Link1/img8.jpg"
import Footer from '../FOOTER/Footer'
const Link1 = () => {
  return (<>
    <div className='link-1 position-relative'>
      <div class="card1">
        <img src={MainIng} loading="lazy" alt="Card 1 Image border border-2 border-dark p-2" />
        <div class="overlayy">
          <h2>IT SOLUTION & CONSULTING</h2>
        </div>
      </div>
    </div >
    <div className='p-1 l1-text'>
      <div className='container mt-4 '>
        <p>Conquer the Business with the Power of ERP, CRM, NAV. Imagine your business as a thriving jungle, buzzing with activity but also tangled with inefficiencies & disconnected processes. This digital wilderness can be tamed with the mighty tools of ERP, CRM and NAV (Microsoft Dynamics NAV).
          Think of ERP as your central clearinghouse, seamlessly connecting all aspects of your business from financials and inventory to manufacturing and production. CRM becomes your digital Rolodex; ensuring satisfied customers become your biggest advocates. Nurturing loyal customer’s relationship carefully tended for maximum growth. NAV, specifically Microsoft Dynamics NAV, acts as your nimble guide through the ever changing digital terrain.
          But these tools aren't solo acts, they're a power couple! When ERP, CRM and NAV work together, they orchestrate a synchronized symphony of efficiency. We, at GenXcel Info Solutions don't just implement software; we customize & integrate your existing systems, tailor dashboards for actionable insights and configure workflows that empower your team to optimize your operations and watch your business flourish in the age of intelligent technology.
        </p>
      </div>

    </div>


    <div className='container p-1 text-center'>
      <div className='row  my-4 reverse align-items-center '>
        <div className='col-lg-6 col-md-6 col-sm-12 '>
        <h2> Maintance & Support Services</h2>
          <p>Forget the days of panic-dialing of tech support at critical hour, your IT shouldn't be a source of stress; it should be a seamless symphony of efficiency. Enter Information Technology Maintenance and Support Services - your digital guardian angels, keeping your network humming and your productivity soaring.</p>
          <p>GenXcel Info Solutions don't just wait for servers to crash and firewalls to crumble. Our proactive maintenance practices, powered by advanced monitoring and analytics, identify potential issues before they disrupt your workflow. Think of it as a digital immune system, boosting your network's resilience.</p>
          <p>We audit your systems, recommend optimization and implement best practices to ensure your network is secure, scalable and future-proof. Invest in Peace of Mind, Reap the Rewards with the assistance of Genxcel Info Solutions. Minimized downtime, enhanced security and optimized performance translate to increased productivity, cost savings, and a stress-free work environment. </p>

        </div>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>
         
          <img src={img4} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
      </div>

      <div className='row  my-4 align-items-center'>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>
         
          <img src={img5} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 '>
        <h2>Infra & Cloud Services</h2>
          <p>The Cloud solutions are the future of business your launch pad to success with flies high with trivial. </p>
          <p>The virtual kingdom where resources expand and shrink on demand, adapting to your business needs like a chameleon. Infrastructure & Cloud Services offer unparalleled scalability ensuring you always have the resources you need without the burden of excess hardware and cut down the lengthy deployment circle. </p>
          <p>Cloud fortresses come equipped with state-of-the-art security features, redundant data centers and disaster recovery plans. Security becomes a shared responsibility, allowing you to focus on your core business, while industry-leading cloud providers protect your valuable data. Cloud services operate on a pay-as-you-go model, transforming fixed costs into variable expenses. You only pay for what you use, minimizing costs and maximizing your return on investment.</p>
          <p>Let GenXcel Info Solutions be your trusted guide to navigate the digital landscape and building a robust, agile and secure foundation for your future success.</p>
        </div>
      </div>

      <div className='row  my-4 reverse align-items-center'>
        <div className='col-lg-6 col-md-6 col-sm-12 '>
        <h2> Hardware as a Services (Haas)</h2>
          <p>Remember the days of hefty upfront costs, tangled wires and outdated equipment dragging down your budget and efficiency? Enter Hardware as a Service (HaaS) with Genxcel Info Solutions to reshape the tech landscape. </p>
          <p>HaaS removes the burden of capital expenditure (CapEx). Instead, access top-tier technology right from laptops, servers, printers and software with low monthly subscriptions. Pay as you go along with scale up or down easily and adapt to your business needs without breaking the bank.</p>
          <p>HaaS brings transparency and predictability to your IT budget. Fixed monthly costs eliminate surprise expenses and hidden fees. Know exactly what you're paying and enjoy the peace of mind of clear and consistent budgeting. It adapts to your growth like a chameleon. Whether you're expanding into new markets or experiencing seasonal fluctuations add or remove hardware instantly, always maintaining the perfect tech balance for your needs. </p>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>
         
          <img src={img6} className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
      </div>

      <div className='row  my-4 align-items-center'>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>
         
          <img src={img7} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 '>
        <h2> Software as a Services (SaaS)</h2>
          <p>SaaS operates on a subscription model, transforming fixed costs into variable expenses. Pay only for what you use and scale your software needs with your business growth, maximizing efficiency and minimizing budgets.</p>
          <p>SaaS brings teams together like never before. Real-time document editing, shared dashboards and seamless communication tools foster collaboration, regardless of location. Break down silos, boost productivity, and watch your team thrive in a connected environment.</p>
          <p>Access cutting-edge features, integrations with other tools and constant improvements, all delivered automatically. Stay ahead of the curve and leverage the latest advancements to keep your business evolving.</p>
          <p>Let us be your trusted guide, navigating the vast cloud landscape and connecting you with the perfect solutions to simplify your operations, empower your workforce, and propel your business to success.</p>
        </div>
      </div>

      <div className='row align-items-center my-4 reverse align-items-center'>
        <div className='col-lg-6 col-md-6 col-sm-12 '>
        <h2> Virtualization</h2>
          <p>That's the magic of virtualization – your digital oasis in the hardware jungle & allows a world where one server acts as many.</p>
          <p>Divide one physical server into multiple virtual machines, maximizing resource utilization and saving space & cost. Think of it as growing your tech garden without needing more land! Scale resources up or down instantly, adapting to peak demands and changing workflows. Virtualization becomes your chameleon, always matching your needs perfectly.</p>
          <p>Replicate your entire system virtually, ensuring business continuity even if hardware hiccups. Virtualization lets you bounce back from anything, keeping your digital oasis thriving. Centralize control, simplify maintenance and say goodbye to hardware headaches. Virtualization makes managing your tech as easy as tending to a single flower.</p>
          <p>Let GenXcel be your digital gardeners, crafting a vibrant and adaptable tech ecosystem for your business to flourish.</p>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12  h-100'>
         
          <img src={img8} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
        </div>
      </div>
    </div>
    <Footer />
  </>)
}

export default Link1