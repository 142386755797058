import React from "react";
import Footer from "../FOOTER/Footer";
import contact from "../Used Images/Contact/contact.jpg";
import Formpage from "./formpage";
import "./contact.css";
let Contactus = () => {
    return (<>
        <div className=' position-relative'>
            <div class="card1 h-100">
                <img src={contact} loading="lazy" alt="Card 1 Image" className="h-100" />
            </div>
        </div >
        <div className="container-fluid text-center contact-img p-1" style={{ backgroundColor: " #ebf4f5" }}>
            <div className="container mt-5">
                <div className="row gap-5 p-1">
                    <div className="col-lg mt-4">
                        <div className="row text-start  ">
                            <div className="row  about-cards p-5 mx-1">
                                <div className="col-lg-12    ">
                                    <h5>Registered Address</h5>

                                    <p className="mt-2 ">GenXcel Info Solutions Private Limited<br />
                                        Office No G-6, Ashoka Plaza, Ground Floor,<br />
                                        Viman Nagar, Nagar Road, Dominos Pizza, Ph:</p>
                                </div>
                                <div className="col-sm-5 ">
                                    <h5>For Enquiries</h5>
                                </div>
                                <div className="col-sm-7 ">
                                    <a class="text nav-link" href="mailto:">Info@gxcel.com</a>
                                </div>
                                <div className="col-sm-5 ">
                                    <h5>For Sales</h5>
                                </div>
                                <div className="col-sm-7 ">
                                    <a class="text nav-link" href="mailto:">Sales@gxcel.com</a>
                                </div>

                                <div className="col-sm-5">
                                    <h5>For Services</h5>
                                </div>
                                <div className="col-sm-7 ">

                                    <a class="text nav-link" href="mailto:">Service@gxcel.com</a>
                                </div>
                                <div className="col-sm-5">
                                    <h5>For Career</h5>
                                </div>
                                <div className="col-sm-7  ">
                                    <a class="text nav-link" href="mailto:">Hr@gxcel.com</a>
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 p-1 map-div">
                                <iframe className="border border-dark rounded p-2 mt-5" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.316144072452!2d73.91027407386044!3d18.559780517977984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c14073bbf99b%3A0x2e3b6dadd58d1c6c!2sGenXcel%20Info%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1705303669437!5m2!1sen!2sin" style={{ border: "0", objectFit: "cover" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg ">
                        <div className="row position-relative text-start">
                            <Formpage />
                        </div>
                    </div>
                </div>
                <div className="row mt-5  fs-4 text-center ">
                    <h4>Social Networking</h4>
                    <div className=" row  mt-4 fs-3 p-3 px-5 s-logo ">
                        <div className="col"> <a href="https://www.facebook.com/GENXCEL" class="bi bi-facebook text-dark"></a></div>
                        <div className="col">  <a class="bi bi-twitter-x text-dark"></a></div>
                        <div className="col"> <a href="https://www.linkedin.com/company/genxcel-info-solutions" class="bi bi-linkedin text-dark"></a></div>
                        <div className="col"><a class="bi bi-instagram text-dark"></a></div>
                        <div className="col"><a href="https://wa.me/+919860099400" class="bi bi-whatsapp text-dark"></a></div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>);
}
export default Contactus;