import React from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import "./index.css";
import Nav from "./NAVBAR/Nav.js";
import Home from "./HOME/Home.js";
import About from "./ABOUT/About.js";
import Products from "./PRODUCT/Products.js";
import Software from "./PRODUCT/Softeware.js";
import Contactus from "./CONTACT/Contactus.js";
import Services from "./SERVICES/Services.js";
import Link1 from "./SERVICES/Link1.js";
import Link2 from "./SERVICES/Link2.js";
import Link3 from "./SERVICES/Link3.js"
import Link4 from "./SERVICES/Link4.js";
import PLink1 from "./PRODUCT/PLink1.js"
import AngleUp from "./FOOTER/AngleUp.js";
let App = () => {
  return (<>
    <BrowserRouter>
      <Nav />
      <AngleUp/>
      <Routes>
        <Route  path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<Products />} />
        <Route path="/Software" element={<Software />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/Services" element={<Services />} />
        <Route exact path="/link1" element={<Link1 />} />
        <Route path="/link2" element={<Link2 />} />
        <Route path="/link3" element={<Link3 />} />
        <Route path="/link4" element={<Link4 />} />
        <Route path="/PLink1" element={<PLink1 />} />
      </Routes>
    </BrowserRouter>
  </>);
}
export default App;
