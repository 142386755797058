import React from 'react'
import img1 from "../Used Images/Products/PLink1/img1.jpg"
import img2 from "../Used Images/Products/PLink1/img2.png"
import img3 from "../Used Images/Products/PLink1/img3.jpg"
import Footer from '../FOOTER/Footer'

function PLink1() {
    return (<>

        <div className='container text-center'>
            <h2 className='mt-4 mb-2'>IT SOFTWARE & CONSULTING</h2>
            <div className='row d-flex  my-4  p-3 align-items-center' >
                <div className='col-lg-6 col-md-6 col-sm-12  h-100 '>
                   
                    <img src={img1} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 '>
                <h2> ERP | CRM | NAV</h2>
                    <p>Think of ERP as your central clearinghouse, seamlessly connecting all aspects of your business from financials and inventory to manufacturing and production. </p>
                    <p>CRM becomes your digital Rolodex; ensuring satisfied customers become your biggest advocates. Nurturing loyal customer’s relationship carefully tended for maximum growth.</p>
                    <p>NAV, specifically Microsoft Dynamics NAV, acts as your nimble guide through the ever changing digital terrain.</p>
                    <p>But these tools aren't solo acts, they're a power couple! When ERP, CRM and NAV work together, they orchestrate a synchronized symphony of efficiency. We, at GenXcel Info Solutions don't just implement software; we customize & integrate your existing systems, tailor dashboards for actionable insights and configure workflows that empower your team to optimize your operations and watch your business flourish in the age of intelligent technology.</p>
                </div>
            </div>

            <div className='row  my-4 reverse  p-3 d-flex align-items-center'>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                <h2> Licensing & Software Solutions</h2>
                    <p>The software world filled with cryptic licenses, complex compatibility and compatibility headaches. GenXcel Info Solutions enter the realm of Licensing & Software Solutions to be your trusted companion for digital landscape.</p>
                    <p>We translate licensing jargon into clear choices, helping you find the perfect software harmony for your business needs. From perpetual licenses to cloud subscriptions, open-source options to tailored enterprise solutions, we orchestrate a software ecosystem that empowers your team and fuels your growth with seamless deployment.</p>
                    <p>We offer robust solutions and bring your cart to the table from across the globe.  We keep our finger on the pulse of the latest software trends, bringing you the most innovative solutions to maximize your competitive edge. From AI-powered platforms to data-driven insights we fuel your digital transformation journey. </p>
                    <p>Please reach us to get navigation through the complex landscape and crafting tailored software ecosystem catered to your from across the world. </p>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12  h-100 '>
                  
                    <img src={img2} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
                </div>
            </div>

            <div className='row my-4  p-3 align-items-center'>
                <div className='col-lg-6 col-md-6 col-sm-12  h-100'>
                   
                    <img src={img3} loading="lazy" className="img-fluid d-block w-100 h-100 border border-2 border-dark p-2" />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 '>
                <h2> End Point Security Solutions</h2>
                    <p>End Point Security Solutions protect business from the vulnerable fortresses, ripe for cyber attacks. Endpoint Security Solutions are your moat and drawbridge, protecting your digital domain from the ever-evolving threatscape.</p>
                    <p>Think of endpoint security as an invisible shield, guarding your devices from malware, phishing, ransomware and more. We deploy advanced detection and prevention technologies which best suites to your business environment, keeping even the stealthiest digital villains at bay. Think of it as having a digital guardian angel, constantly analyzing and adapting to protect your devices around the clock.</p>
                    <p>Your digital perimeter extends far beyond your personal devices. Servers, printers, even smart home gadgets all need protection. We offer comprehensive endpoint security solutions that safeguard your entire network, ensuring no vulnerability goes unnoticed.</p>
                    <p>Let GenXcel Info Solution’s be your trusted cyber warriors, crafting a customized defense system that shields your endpoints and empowers your digital freedom. Contact us today and unlock the peace of mind that comes with knowing you're truly protected.</p>
                </div>
            </div>
        </div>
        

        <div>
            <div className='container fs-5 mt-4 shadow-lg p-4 mb-5' >
                <h3>GenXcel Info Solutions offer's below Software Solutions :</h3>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <ul>
                            <li>Lync.</li>
                            <li>Adobe.</li>
                            <li>VMware.</li>
                            <li>Citrix.</li>
                            <li>AutoCAD.</li>
                            <li>AutoDesk.</li>
                            <li>Microsoft Office 365.</li>
                            <li>Network management tools.</li>
                            <li>Microsoft Operating Software.</li>
                            <li>Server & Storage management tools.</li>
                        </ul>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <ul>
                            <li>Cisco.</li>
                            <li>Ansys.</li>
                            <li>Commvault.</li>
                            <li>Talley ERP.</li>
                            <li>File Maker Pro.</li>
                            <li>InfoWork Software.</li>
                            <li>Geo Fluid Software's.</li>
                            <li>Virtual Load Balancer (VLM)</li>
                            <li>Any other indigenous & imported software solutions.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>);
}

export default PLink1