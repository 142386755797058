import React from "react";
import Footer from "../FOOTER/Footer";
import { NavLink } from "react-router-dom";
import "./home.css";
import logo1 from "../Used Images/Partners/fujitsu.png";
import logo2 from "../Used Images/Partners/IBM.png";
import logo3 from "../Used Images/Partners/adobe.png";
import logo4 from "../Used Images/Partners/cisco.png";
import logo5 from "../Used Images/Partners/citricx.png";
import logo6 from "../Used Images/Partners/d-link.png";
import logo7 from "../Used Images/Partners/dell.png";
import logo8 from "../Used Images/Partners/eizo.png";
import logo9 from "../Used Images/Partners/gujitus.png";
import logo10 from "../Used Images/Partners/hikevision.png";
import logo11 from "../Used Images/Partners/hp.png";
import logo12 from "../Used Images/Partners/huawai.png";
import logo13 from "../Used Images/Partners/ingram.png";
import logo14 from "../Used Images/Partners/lenovo.png";
import logo15 from "../Used Images/Partners/microsoft.png";
import logo16 from "../Used Images/Partners/pngwing.com (19).png";
import logo17 from "../Used Images/Partners/netapp.png";
import logo18 from "../Used Images/Partners/polycom.png";
import logo19 from "../Used Images/Partners/seagate.png";
import logo20 from "../Used Images/Partners/tp-link.png";
import logo21 from "../Used Images/Partners/umware.png";
import logo22 from "../Used Images/Partners/veritas.png";
import logo23 from "../Used Images/Partners/wd.png";



import MyVideo from "../Used Images/HomeImages/MyVideo.mp4"

let Home = () => {
  return (<>
  
    <div className="position-relative home">
      <video autoPlay loop muted preload="play" >
        <source src={MyVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>

      <div class="position-absolute top-0  content p-1 home-text" style={{ height: "100%" }}>
        <div className="p-2 posttion-relative" style={{ marginTop: "4%", marginLeft: "5vw", height: "10vw" }}>
          <h1>Accelerate Your Business <br />With The Best IT Consulting  <br />Develepment Services.</h1>
          <NavLink to="/Contactus" className="nav-link ">
            <button type="button" class="btn btn-outline-info col-sm-4 fw-bold">Contact</button>
          </NavLink>

          <p className="mt-4">
            We understand your need, analize, design customised, <br />& clients centric solutions for your business </p>
        </div>
      </div>
    </div>

    <div style={{ backgroundColor: " #ebf4f5" }} className="p-1">
      <div className="container-fluid text-center mt-5 home-pragraph mb-5">
        <div className="container">
          <p className="text-center fw-bold fs-4 ">GenXcel: Your trusted partners for tailored IT solutions.</p>
          <p className="mt-4">At <strong>GenXcel</strong>, we craft bespoke solutions that empower your business to thrive. Our passion lies in understanding your unique challenges and delivering world-class solutions from leading brands like HP, Lenovo, Microsoft and many more global technologies. </p>
          <p className="mt-3">The leadership team consists of seasoned IT veterans knows the landscape inside-out, ensuring you get the best advice and service. We put your needs at the heart of everything we do, exceeding expectations and exceeding satisfaction. Genxcel Info Solutions offer premium solutions at value-driven prices, optimizing your IT budget without compromising quality. From hardware and software to network security and data management to outsourcing services, we tackle your most critical needs with reliable efficiency. We're a nationwide IT partner, serving businesses across India with dedicated support and local expertise.</p>
        </div>
      </div>
      <div>
        <div className="container text-center position-relative py-2 product-div">
          <h2 className="mt-2">OUR EXPERTIES</h2>
          <div className="row cards gap-3 product-name justify-content-center mt-5">
            <div className="col shadow-lg p-4 mb-5  rounded">
              <NavLink to="/products" className="nav-link dropdown-item">
                <a><h4> IT Hardware & Solutions</h4></a>
              </NavLink>
            </div>
            <div className="col shadow-lg p-4 mb-5  rounded">
              <NavLink to="/PLink1" className="nav-link dropdown-item">
                <a><h4> IT Software & Products</h4></a>
              </NavLink>

            </div>
            <div className="col shadow-lg p-4 mb-5  rounded">
              <NavLink to="/Link1" className="nav-link dropdown-item">
                <a><h4>IT Solution & Consulting </h4></a>
              </NavLink>

            </div>
          </div>
          <div className="row cards  gap-3 product-name justify-content-center">
            <div className="col shadow-lg p-4 mb-5 rounded">
              <NavLink to="/Link4" className="nav-link dropdown-item">
                <a>  <h4> IT Enabled & Services</h4></a>
              </NavLink>
            </div>
            <div className="col shadow-lg p-4 mb-5  rounded">
              <NavLink to="/Link3" className="nav-link dropdown-item">
                <a><h4>IT Staffing & Requirments</h4></a>
              </NavLink>
            </div>
            <div className="col shadow-lg p-4 mb-5  rounded">
              <NavLink to="/Link2" className="nav-link dropdown-item">
                <a><h4> IT Software & Development</h4></a>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center container p-1 ">
        <h2 className="mt-3">Why Choose Us</h2>
        <div className='row text-center shadow-lg p-4 fw-bold mx-3 mb-4 mt-5 why-choose-us' >
          <div className='col-lg-4 col-sm-4 col-md-4 p-3 '>
            <h5>Proficient Presales Team</h5>
          </div>
          <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
            <h5>Proficient Tech Team</h5>
          </div>
          <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
            <h5>Round the Clock Tech Support</h5>
          </div>
          <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
            <h5>Consistent Post Sales </h5>
          </div>
          <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
            <h5>Cross Country Delivery Feasibility</h5>
          </div >
          <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
            <h5>Fully complied with Corporate governance</h5>
          </div>
        </div>
      </div>

    </div>


    {/* scroling effect */}
    <div class="slider home-logo text-center ">
      <h2  >We Are Associated With</h2>
      <div class="slide-track mt-5">
        <div class="slide">
          <img src={logo1} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo2} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo3} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo4} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo5} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo6} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo7} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo8} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo9} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo10} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo11} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo12} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo13} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo14} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo15} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo16} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo17} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo18} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo19} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo20} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo21} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo22} loading="lazy" />
        </div>
        <div class="slide">
          <img src={logo23} loading="lazy" />
        </div>
      </div>
    </div>
    <Footer />
  </>);
}
export default Home;