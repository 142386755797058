import React from "react";
import Footer from "../FOOTER/Footer";
import "./services.css";
import BackgroungImg from "../Used Images/SERVICES/Services.jpg";
import img1 from "../Used Images/SERVICES/img1.jpg"
import img2 from "../Used Images/SERVICES/img2.jpg"
import img3 from "../Used Images/SERVICES/img3.jpg"
import img4 from "../Used Images/SERVICES/img4.jpg"

import { Link } from "react-router-dom";

let Services = () => {
    return (<>
        <div className=" position-relative" >
            <div className="position-relative w-100 h-100" >
                <img src={BackgroungImg} loading="eager" className="img-fluid d-block w-100 h-100" />
                <div className="position-absolute w-100 h-100 top-0 left-0 text-center d-flex justify-content-center align-items-center text-white">
                    <h2>SERVICES</h2>
                </div>
            </div>
        </div>

        <div className='container'>
            <div className='row gy-3 mt-3'>
                <div className='col-lg-6 col-md-6 col-sm-6'>
                    <div class="hover hover-2 text-white rounded"><img src={img1} loading="eager" className='img-fluid' alt="" />
                        <div class="hover-overlay"></div>
                        <div class="hover-2-content px-5 py-4">
                            <h4 class="hover-2-title text-uppercase font-weight-bold mb-0"> <span class="font-weight-light">IT Solution & Consulting </span></h4>
                            <p class="hover-2-description text-uppercase mb-0 ">
                                <Link to="/link1" className="text-white"><a >Read More</a></Link></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="hover hover-2 text-white rounded"><img src={img2} loading="eager" className='img-fluid' alt="" />
                        <div class="hover-overlay"></div>
                        <div class="hover-2-content px-5 py-4">
                            <h3 class="hover-2-title text-uppercase font-weight-bold mb-0"> <span class="font-weight-light">IT Softwares & Development </span></h3>
                            <p class="hover-2-description text-uppercase mb-0 ">
                                <Link to="/link2" className="text-white"><a >Read More</a></Link></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row gy-3 mt-1'>
                <div className='col-lg-6 col-md-6 col-sm-6'>
                    <div class="hover hover-2 text-white rounded"><img src={img3} loading="eager" className='img-fluid' alt="" />
                        <div class="hover-overlay"></div>
                        <div class="hover-2-content px-5 py-4">
                            <h3 class="hover-2-title text-uppercase font-weight-bold mb-0"> <span class="font-weight-light">IT Staffing & Recruiting </span></h3>
                            <p class="hover-2-description text-uppercase mb-0 "><Link to="/link3" className="text-white"><a >Read More</a></Link></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="hover hover-2 text-white rounded"><img src={img4} loading="eager" className='img-fluid' alt="" />
                        <div class="hover-overlay"></div>
                        <div class="hover-2-content px-5 py-4">
                            <h3 class="hover-2-title text-uppercase font-weight-bold mb-0"> <span class="font-weight-light">IT Enabled Services </span></h3>
                            <p class="hover-2-description text-uppercase mb-0 "><Link to="/link4" className="text-white"><a >Read More</a></Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/*  */}


        <div className="container-fluid p-1 service-page mt-4" style={{ backgroundColor: "#ebf4f5" }}>
            <div className="container mt-3 ">
                <p>
                    With the advent of globalization a decade ago and an open door policy being adopted by India, the world's outlook towards India has undergone a massive change. India has
                    played a major contribution and crucial role in becoming one of the key leaders in the field of Information Technology development and ITES HUB. As a result, a huge market
                    demand for IT infrastructure and solutions is being observed in the recent past. GenXcel Info Solutions Pvt. Ltd. is your most trusted partner for success, providing high tech,
                    low cost and sustainable IT solutions that will enhance your efficiency, productivity, quality, data safety of resources to place your organization in global standard.
                </p>
            </div>
        </div>
        <Footer />
    </>);
}
export default Services;