import React from "react";
import "./nav.css";
import { NavLink, Link } from "react-router-dom";
import GENXCEL from "../Used Images/LOGO/Logo.png";
let Nav = () => {
    return (<>
        <nav class="navbar navbar-expand-lg bg-body-tertiary ">
            <div class="container-fluid ">
                <img src={GENXCEL} loading="lazy" alt="" className=" col-3 navbar-brand ms-3 nav-logo fw-medium  " />
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                 data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar-link me-5 gap-3 fs-5 fw-medium">
                        <li class="nav-item">
                            <NavLink to="/home" className="nav-link">
                                <a>Home</a>
                            </NavLink>
                        </li>
                        <li class="nav-item">
                            <NavLink to="/About" className="nav-link ">
                                <a>About</a>
                            </NavLink>
                        </li>
                        <li class="nav-item ">

                            <NavLink to="/products" className="nav-link">
                                <a>Products</a>
                            </NavLink>
                        </li>
                       
                        <li class="nav-item dropdown">
                            <NavLink to="/Services" className="nav-link dropdown-item">
                                <a>Services</a>
                            </NavLink>
                            <ul class="dropdown-menu fade-up">
                                <li>
                                    <NavLink to="/PLink1" className="nav-link dropdown-item">
                                        <a>IT Software & Consulting</a>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Link1" className="nav-link dropdown-item">
                                        <a>IT Solution & Consulting</a>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Link4" className="nav-link dropdown-item">
                                        <a>IT Enabled Services</a>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Link3" className="nav-link dropdown-item">
                                        <a>IT Staffing & Recruiting</a>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Link2" className="nav-link dropdown-item">
                                        <a>IT Softwares & Development</a>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <NavLink to="/Contactus" className="nav-link ">
                                <a>Contact</a>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    </>);
}
export default Nav;
