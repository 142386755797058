import React from 'react'
import BPO from '../Used Images/SERVICES/Link4/BPO.png'
import Footer from '../FOOTER/Footer';

const EnabledAndServices = () => {
  let a = 1;
  let b = 1;
  let c = 1;
  let d = 1;
  let e = 1;
  let f = 1;
  const showA1 = () => {
    if (a == 1) {
      document.getElementById("a").style.display = "block";
      document.getElementById("b").style.display = "none";
      document.getElementById("c").style.display = "none";
      document.getElementById("d").style.display = "none";
      document.getElementById("e").style.display = "none";
      document.getElementById("f").style.display = "none";
      a = 0;
      b = 1;
      c = 1;
      d = 1;
      e = 1;
      f = 1;
    }
    else if (a == 0) {
      a = 1;
      document.getElementById("a").style.display = "none";
    }
  }

  const showA2 = () => {
    if (b == 1) {
      document.getElementById("b").style.display = "block";
      document.getElementById("a").style.display = "none";
      document.getElementById("c").style.display = "none";
      document.getElementById("d").style.display = "none";
      document.getElementById("e").style.display = "none";
      document.getElementById("f").style.display = "none";
      b = 0;
      a = 1;
      c = 1;
      d = 1;
      e = 1;
      f = 1;
    }
    else if (b == 0) {
      b = 1;
      document.getElementById("b").style.display = "none";
    }
  }

  const showA3 = () => {
    if (c == 1) {
      document.getElementById("c").style.display = "block";
      document.getElementById("a").style.display = "none";
      document.getElementById("b").style.display = "none";
      document.getElementById("d").style.display = "none";
      document.getElementById("e").style.display = "none";
      document.getElementById("f").style.display = "none";
      c = 0;
      a = 1;
      b = 1;
      d = 1;
      e = 1;
      f = 1;
    }
    else if (c == 0) {
      c = 1;
      document.getElementById("c").style.display = "none";
    }
  }
  const showA4 = () => {
    if (d == 1) {
      document.getElementById("d").style.display = "block";
      document.getElementById("a").style.display = "none";
      document.getElementById("b").style.display = "none";
      document.getElementById("c").style.display = "none";
      document.getElementById("e").style.display = "none";
      document.getElementById("f").style.display = "none";
      d = 0;
      a = 1;
      b = 1;
      c = 1;
      e = 1;
      f = 1;
    }
    else if (d == 0) {
      d = 1;
      document.getElementById("d").style.display = "none";
    }
  }
  const showA5 = () => {
    if (e == 1) {
      document.getElementById("e").style.display = "block";
      document.getElementById("a").style.display = "none";
      document.getElementById("b").style.display = "none";
      document.getElementById("c").style.display = "none";
      document.getElementById("d").style.display = "none";
      document.getElementById("f").style.display = "none";
      e = 0;
      a = 1;
      b = 1;
      c = 1;
      d = 1;
      f = 1;
    }
    else if (e == 0) {
      e = 1;
      document.getElementById("e").style.display = "none";
    }
  }
  const showA6 = () => {
    if (f == 1) {
      document.getElementById("f").style.display = "block";
      document.getElementById("a").style.display = "none";
      document.getElementById("b").style.display = "none";
      document.getElementById("c").style.display = "none";
      document.getElementById("d").style.display = "none";
      document.getElementById("e").style.display = "none";
      f = 0;
      a = 1;
      b = 1;
      c = 1;
      d = 1;
      e = 1;
    }
    else if (f == 0) {
      f = 1;
      document.getElementById("f").style.display = "none";
    }
  }



  return (<>
    <div className="position-relative link-2 bg-blast" >
      <div className="position-relative w-100" style={{ height: "35vw" }}>
        <img src={BPO} loading="lazy" className="img-fluid d-block w-100 h-100" />
        <div className=" position-absolute w-100 h-100 top-0 left-0 text-center d-flex justify-content-center align-items-center text-white">
          <h2>IT Enabled Services</h2>
        </div>
      </div>
    </div>

    <div className=' p-3'>
      <div className='container mt-3'>
        <p>At Genxcel Info Solutions, we offer Business Process Outsourcing <b>(BPO) </b>
          Services to lift up your operations and drive smoothly towards the growth.
          We enable you to focus on your core competencies while we handle critical
          business processes with precision.</p>
      </div>

    </div>

    <div className='container h6Color '>
      <div className='row text-center shadow-lg p-4 fw-bold mx-3 about-text'>
        <div className='col-lg-4 col-sm-4 col-md-4 p-3 '>
        <button type="button" class="btn btn-outline-primary"> <a onClick={showA1}>Accounts & Finance</a></button>
        </div>
        <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
        <button type="button" class="btn btn-outline-primary">  <a onClick={showA2}>HR Services</a></button>
        </div>
        <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
        <button type="button" class="btn btn-outline-primary"> <a onClick={showA3}>Data Processing</a></button>
        </div>
        <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
        <button type="button" class="btn btn-outline-primary">  <a onClick={showA4}>Chat & Email Support:</a></button>      
        </div>
        <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
        <button type="button" class="btn btn-outline-primary"> <a onClick={showA5}>Technical Support:</a></button>      
        </div >
        <div className='col-lg-4 col-sm-4 col-md-4 p-3'>
        <button type="button" class="btn btn-outline-primary"> <a onClick={showA6}>Administration:</a></button>                
        </div>
      </div>
    </div>

    <div className='mt-5 mb-5 border-bottom border-3'>
      <div id="a" className='container '>
        <h5>Accounts & Finance</h5>
        <p>Say goodbye to endless bookkeeping and hello to streamlined financial operations. Our Finance & Accounting BPO experts handle everything from A/R & A/P to payroll & tax, so you can focus on strategic decisions, not daily drudgery.</p>
        <h5> We offer:</h5>
        <ol>
          <li>Accuracy & Efficiency: Eliminate errors and automate workflows for flawless financial management.</li>
          <li>Cost Savings: Reduce overhead and free up resources for core business investments.</li>
          <li>Scalability & Flexibility: Adapt to your evolving needs with on-demand expertise.</li>
          <li>Real-Time Insights: Gain deeper financial clarity with data-driven dashboards and reporting.</li>
        </ol>
        <p>** Stop crunching numbers and start driving growth. Contact us today to unlock the power of Finance & Accounting BPO!</p>
      </div>

      <div id="b" className='container'>
        <h5>HR Services</h5>
        <p>Feeling overwhelmed by recruitment, payroll, or employee relations? Our HR BPO experts take the weight off your shoulders, so you can invest in your people and watch your business thrive.</p>
        <h5>We offer:</h5>
        <ol>
          <li>Talent Acquisition: Attract and hire top performers, faster and more effectively.</li>
          <li>Payroll & Benefits: Ensure flawless payroll processing and benefits administration.</li>
          <li>Compliance & Regulations: Stay ahead of complex HR legislation and policies.</li>
          <li>Employee Engagement: Boost morale and productivity with innovative HR programs.</li>
        </ol>
        <p>**Unleash the full potential of your workforce. Contact us today and discover the transformative power of HR BPO!</p>
      </div>

      <div id="c" className='container'>
        <h5>Data Processing</h5>
        <p>Struggling to decipher your data deluge? Our expert Data Processing BPO team transforms raw information into actionable insights. We clean, categorize, and analyze your data, equipping you with the knowledge to make smarter decisions, faster.</p>
        <h5>We offer:</h5>
        <ol>
          <li>Data Extraction & Migration: Seamlessly transfer and organize your data from any source.</li>
          <li>Data Cleansing & Standardization: Ensure accuracy and consistency for reliable analysis.</li>
          <li>Data Analysis & Reporting: Gain deeper understanding through custom reports and dashboards.</li>
          <li>Machine Learning & Automation: Optimize processes and unlock data-driven insights.</li>
        </ol>
        <p> **Stop swimming in data, start surfing for success. Contact us today and let Data Processing BPO be your lifeguard to actionable insights</p>
      </div>

      <div id="d" className='container'>
        <h5>Chat & Email Support:</h5>
        <p>Ditch the inbox overload, engage customers like champs: Chat & Email BPO to the rescue!</p>
        <p>Feeling swamped by customer inquiries? Our Chat & Email BPO team is your answer, providing round-the-clock, omni channel support that delights customers and boosts your bottom line.</p>

        <h5>We offer:</h5>
        <ol>
          <li>24/7 Customer Engagement:
            Reach your audience anytime, anywhere, building brand loyalty and trust.</li>
          <li>Multi-channel Expertise: Seamlessly handle chat, email, and social media inquiries for a unified experience.</li>
          <li>Personalized Interactions:
            Our agents connect with empathy and knowledge, resolving issues faster and building rapport.</li>
          <li>4 Advanced Analytics: Gain actionable insights from customer interactions to improve service and engagement.</li>
        </ol>
        <p> ** Stop firefighting customer emails, start building relationships. Contact us today to unlock the power of Chat & Email BPO!</p>
      </div>

      <div id="e" className='container'>
        <h5>Technical Support:</h5>
        <p>Ditch the tech headaches, unlock innovation: Technical Process BPO your digital wingman.</p>
        <p>Bogged down by IT glitches, software updates, or security worries? Our Technical Process BPO experts handle the tech heavy lifting, so you can focus on your core business and propel it forward.</p>

        <h5>We offer:</h5>
        <ol>
          <li>IT Infrastructure Management: Maintain servers, networks, and applications flawlessly</li>
          <li>Software Development & Support: Build and maintain custom software for optimized performance.</li>
          <li>Cybersecurity & Data Protection: Keep your data safe and meet all compliance regulations.</li>
          <li>Cloud Computing & Automation: Leverage the cloud for scalability and cost-efficiency.</li>
        </ol>
        <p> **Stop wrestling with tech woes, start accelerating your business. Contact us today and discover the transformative power of Technical Process BPO!</p>
      </div>

      <div id="f" className='container'>
        <h5>Administration:</h5>
        <p>Unchain your productivity: Free yourself from admin chaos with Administration Process BPO.</p>
        <p>Drowning in paperwork, scheduling, and logistics? Our expert Administration Process BPO team dives in, tackles the details and leaves you free to focus on what matters most: your core business.</p>
        <h5>We offer:</h5>
        <ol>
          <li>Streamlined Workflows: Frictionless processes for purchase orders, travel arrangements, and document management.</li>
          <li>Reduced Costs: Save time and resources by outsourcing administrative tasks.</li>
          <li>Improved Accuracy: Eliminate errors and ensure compliance with regulations.</li>
          <li>Enhanced Scalability: Easily adapt to your evolving needs with flexible BPO solutions.</li>
        </ol>
        <p> **Stop wrestling with admin burdens, start thriving. Contact us today and discover the liberating power of Administration Process BPO!</p>
      </div>
    </div>


    <div className='container mb-5'>
      <h4>What we offer as BPO Services provider.</h4>
      <div className='row gy-3 mt-2'>
        <div className='col-lg-6'>
          <div class="card about-cards">
            <div class="card-body">
              <h5 class="card-title">Cost Efficacy:</h5>
              <p class="card-text">By outsourcing your non-core business processes to GenXcel Info Solutions, you can significantly reduce operational costs, Administrative hassles and overheads, freeing up resources for strategic initiatives and revenue generation</p>

            </div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div class="card about-cards" >
            <div class="card-body">
              <h5 class="card-title">Access to Expertise:</h5>
              <p class="card-text">Benefit from our team of industry experts who possess in-depth knowledge and experience in their respective domains. You can trust the professionals of GenXcel Info Solutions to deliver exceptional results and contribute to your business’s success.</p>

            </div>
          </div>
        </div>
      </div>
      <div className='row gy-3 my-2'>
        <div className='col-lg-6'>
          <div class="card about-cards" >
            <div class="card-body">
              <h5 class="card-title">Business Scalability:</h5>
              <p class="card-text">Flexible BPO services can scale up or down based on your business needs. GenXcel Info Solutions can streamline operation to match the rapid growth or need to reorganize during tilt periods. Genxcel Info Solutions as a BPO service provider have the ability to adapt to your requirements.</p>
            </div>
          </div>
        </div>
        <div className='col'>
          <div class="card about-cards p-3 pb-2" >
            <div class="card-body ">
              <h5 class="card-title">Time Savings:</h5>
              <p class="card-text ">Our efficient BPO services save you valuable time by handling time-consuming and repetitive tasks, allowing you to allocate your time and resources to more critical aspects of your business. </p>

            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>)
}

export default EnabledAndServices