      import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
const Formpage = () => {
  // form submit
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_z8toz69', 'template_cl33bp4', form.current, {
      publicKey: 'VJCdrmRSwc4igkNNe',
    })
      .then(() => {
        alert('SUBMITED SUCCESSFULLY!');
        window.location.reload(false);
      },
        (error) => {
          alert('FAILED TO SUBMIT', error.text);
          window.location.reload(false);
        },
      );
  };
  return (<>
    <div class="Fields">
      <div>
        <div class="formContainer container about-cards" >

          <form ref={form} onSubmit={sendEmail}>
            <div class="Fields ">
              <div>
                <h3>Get In Touch</h3>
                <label for="fname">Full Name</label>
                <input type="text" name="name" placeholder='Your Name' pattern="[A-Za-z\s]+" required="required" />
                <label for="email"> Email</label>
                <input type="email" id="email" name="email" placeholder='Your Email' required="required" />
                <label for="adr"> Number</label>
                <input type="number" id="phno" name="user_number" placeholder='Number' pattern="[0-9]{10}" required="required" />
                <label for="adr"> Message</label>
                <input type="text" id="adr" style={{ paddingBottom: "100px" }} name="message" placeholder='Meaasge..' required="required" />
              </div>
            </div>
            <div className='text-center'>
              <input type="submit" value="Submit" className='col-sm-6' />
            </div>
          </form>

        </div>
      </div>
    </div>
  </>);
};
export default Formpage;